.ql-toolbar.ql-snow {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.ql-container.ql-snow {
  min-height: 100px;
  border: 0;
}

.ql-editor[contenteditable="false"]{
  background-color: #f0f2f5 !important;
}